import { If } from 'Conditionals';
import { Fieldset, Input, LoadingButton, Toggle } from 'FormElements';
import { Notice } from 'Layout';
import { showWarningNotice } from 'Library/notifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import useCountries from 'ReduxHooks/useCountries';
import useUser from 'ReduxHooks/useUser';
import API from '../../stores/API';
import './UserEconomyTab.scss';

function UserEconomyTab () {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isConnectLoading, setIsConnectLoading] = useState(false);
  const [paymentMethodDescription, setPaymentMethodDescription] = useState('');
  const [vatError, setVatError] = useState('');
  const [companyRegError, setCompanyRegError] = useState('');
  const [bankAccountError, setBankAccountError] = useState('');
  const { user, paymentMethods, updateField, getPaymentMethods, refreshStripe } = useUser();
  const { countrySettings, getCountrySettings } = useCountries();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    loadDependencies();

    if (urlParams.has('refreshStripe')) {
      refreshStripe(user.id);
    }
  }, []);

  useEffect(() => setLabel(), [paymentMethods, user.payment_method_id, isLoading]);

  const setLabel = () => {
    if (!paymentMethods || !user.payment_method_id || isLoading) {
      return;
    }

    const paymentMethod = paymentMethods.find(({ id }) => id === user.payment_method_id);

    if (!paymentMethod) {
      return;
    }

    setPaymentMethodDescription(paymentMethod.description);
  };

  const validateCompanyRegNumber = (value, countryCode) => {
    if (!value) return true;

    const countryCodeLower = countryCode.toLowerCase();
    if (countryCodeLower !== 'se' && countryCodeLower !== 'dk') return true;

    const regNumber = value.trim();

    switch (countryCodeLower) {
      case 'se':
        if (!/^\d{6}-\d{4}$/.test(regNumber)) {
          setCompanyRegError(t('Swedish company registration number must be in format: XXXXXX-XXXX'));
          return false;
        }
        break;
      case 'dk':
        if (!/^\d{8}$/.test(regNumber)) {
          setCompanyRegError(t('Danish company registration number must be 8 digits'));
          return false;
        }
        break;
    }

    setCompanyRegError('');
    return true;
  };

  const formatCompanyRegNumber = (value, countryCode) => {
    if (!value) return value;

    const regNumber = value.trim();
    const countryCodeLower = countryCode.toLowerCase();

    // Only format for SE and DK
    if (countryCodeLower !== 'se' && countryCodeLower !== 'dk') {
      return regNumber;
    }

    switch (countryCodeLower) {
      case 'se':
        // Remove any existing hyphens and add one after 6 digits
        const cleanNumber = regNumber.replace(/-/g, '');
        if (cleanNumber.length > 6) {
          return cleanNumber.slice(0, 6) + '-' + cleanNumber.slice(6);
        }
        return cleanNumber;
      case 'dk':
        // Just return the number as is, no formatting needed
        return regNumber;
      default:
        return regNumber;
    }
  };

  const handleCompanyRegNumberChange = (value) => {
    const countryCode = user.country?.code || 'se';
    const formattedValue = formatCompanyRegNumber(value, countryCode);
    validateCompanyRegNumber(formattedValue, countryCode);
    updateField('company_registration_number', formattedValue);
  };

  const validateVatNumber = (value, countryCode) => {
    if (!value) return true;
    if (countryCode.toLowerCase() === 'global') return true;

    const countryCodeLower = countryCode.toLowerCase();
    if (countryCodeLower !== 'se' && countryCodeLower !== 'dk') return true;

    const vatNumber = value.toUpperCase().trim();
    const countryPrefix = countryCode.toUpperCase();

    if (!vatNumber.startsWith(countryPrefix)) {
      setVatError(t('VAT number must start with') + ' ' + countryPrefix);
      return false;
    }

    const numberPart = vatNumber.slice(countryPrefix.length);
    const isValid = countryCodeLower === 'se'
      ? /^\d{12}$/.test(numberPart)
      : /^\d{8}$/.test(numberPart);

    if (!isValid) {
      setVatError(t(`VAT number must contain ${countryCodeLower === 'se' ? '12' : '8'} digits after ${countryPrefix}`));
      return false;
    }

    setVatError('');
    return true;
  };

  const formatVatNumber = (value, countryCode) => {
    if (!value) return value;
    if (countryCode.toLowerCase() === 'global') return value;

    const vatNumber = value.toUpperCase().trim();
    const countryPrefix = countryCode.toUpperCase();

    return vatNumber.startsWith(countryPrefix)
      ? vatNumber
      : countryPrefix + vatNumber;
  };

  const handleVatNumberChange = (value) => {
    const countryCode = user.country?.code || 'se';
    const formattedValue = formatVatNumber(value, countryCode);
    validateVatNumber(formattedValue, countryCode);
    updateField('vat_identification_number', formattedValue);
  };

  const validateBankAccountNumber = (value) => {
    if (!value) return true;
    // Only allow digits for bank accounts
    return /^\d+$/.test(value);
  };

  const validateGiroNumber = (value) => {
    if (!value) return true;
    // Allow digits and hyphens for bankgiro and plusgiro
    return /^[\d-]+$/.test(value);
  };

  const isBankAccount = (description) => {
    const desc = description?.toLowerCase() || '';
    return desc.includes('bankkonto');
  };

  const isGiroNumber = (description) => {
    const desc = description?.toLowerCase() || '';
    return desc.includes('bankgiro') || desc.includes('plusgiro');
  };

  const handlePaymentMethodValueChange = (value) => {
    if (isBankAccount(paymentMethodDescription)) {
      if (validateBankAccountNumber(value)) {
        setBankAccountError('');
        updateField('payment_method_value', value);
      } else {
        setBankAccountError(t('Bank account number can only contain digits'));
      }
    } else if (isGiroNumber(paymentMethodDescription)) {
      if (validateGiroNumber(value)) {
        setBankAccountError('');
        updateField('payment_method_value', value);
      } else {
        setBankAccountError(t('Bankgiro/Plusgiro number can only contain digits and hyphens'));
      }
    } else {
      updateField('payment_method_value', value);
    }
  };

  const handleCityChange = (value) => {
    updateField('invoice_city', value.toUpperCase());
  };

  function redirectToStripeConnect (user) {
    return async () => {
      setIsConnectLoading(true);
      const data = await API.GET(`/users/${user}/stripe-connect`);
      setIsConnectLoading(false);

      if (data.status !== 'ok') {
        showWarningNotice(`💔 ${data.errors.error_message}`);
        return;
      }

      window.location = data.url;
    };
  }

  const loadDependencies = async () => {
    setIsLoading(true);
    await Promise.all([
      getPaymentMethods(user.country_id),
      getCountrySettings()
    ]);
    setIsLoading(false);
  };

  const getSelectedPaymentMethod = () => {
    if (!paymentMethods || !user.payment_method_id || isLoading) return null;
    return paymentMethods
      .map(({ id, description }) => ({ value: id, label: description }))
      .find(({ value }) => value === user.payment_method_id);
  };

  return (
    <div className="user-economy-tab">
      {countrySettings && countrySettings.self_invoice_info &&
        <>
          <Notice type='info' className="notice">
            <h4>{t('Information about self invoice')}</h4>
            <p dangerouslySetInnerHTML={{ __html: countrySettings.self_invoice_info.replace('\n', '<br />') }} />
          </Notice>
        </>}

      <If condition={user.has_stripe_connect}>
        <Fieldset legend={t('Stripe Account')} className="fieldset">
          <div className="card">
            <div className="section">
              <div className="section">
                <LoadingButton
                  text={user.stripe_connect_account_id ? t('Reconnect your Stripe Account with Lavendla') : t('Connect your Stripe Account with Lavendla')}
                  isLoading={isConnectLoading}
                  className="white"
                  onClick={redirectToStripeConnect(user.id)}
                />
              </div>

              {(user.stripe_connect_account_id && !user.stripe_connect_account) && (
                <div className="stripe-connect-id">
                  {t('Stripe Account ID')}: {user.stripe_connect_account_id}
                </div>
              )}

              <div>
                <Toggle
                  defaultChecked={user.has_therapy_vat}
                  label={t('Are you registered for VAT?')}
                  desc={t('If this is selected VAT will be included in your payments.')}
                  onChange={value => updateField('has_therapy_vat', value)}
                />
              </div>
            </div>

            {user.stripe_connect_account ? (
              <>
                <div className="section">
                  <h4 className="section-header">{t('Account Status')}</h4>
                  <div className="grid-layout">
                    <span>{t('Stripe Account ID')}:</span>
                    <span className="stripe-account__id">{user.stripe_connect_account_id}</span>
                    <span>{t('Charges Enabled')}:</span>
                    <span className={`stripe-account__status--${user.stripe_connect_account.charges_enabled ? 'success' : 'error'}`}>
                      {user.stripe_connect_account.charges_enabled ? '✅' : '❌'}
                    </span>
                    <span>{t('Payouts Enabled')}:</span>
                    <span className={`stripe-account__status--${user.stripe_connect_account.payouts_enabled ? 'success' : 'error'}`}>
                      {user.stripe_connect_account.payouts_enabled ? '✅' : '❌'}
                    </span>
                    <span>{t('Default Currency')}:</span>
                    <span className="stripe-account__value">{user.stripe_connect_account.default_currency?.toUpperCase()}</span>
                  </div>
                </div>

                {user.stripe_connect_account.capabilities && (
                  <div className="section">
                    <h4 className="section-header">{t('Capabilities')}</h4>
                    <p>
                      {Object.entries(user.stripe_connect_account.capabilities).map(([key, value]) => (
                        <span key={key} className={`stripe-account__capability stripe-account__capability--${value === 'active' ? 'active' : 'inactive'}`}>
                          {key.replace(/_/g, ' ').toUpperCase()}: {value}
                        </span>
                      ))}
                    </p>
                  </div>
                )}

                {user.stripe_connect_account.requirements && (
                  <div className="section">
                    <h4 className="section-header">{t('Requirements')}</h4>
                    <div className="grid-layout">
                      <span>{t('Past Due')}:</span>
                      <span className={`stripe-account__requirement stripe-account__requirement--${user.stripe_connect_account.requirements.past_due?.length ? 'error' : 'none'}`}>
                        {user.stripe_connect_account.requirements.past_due?.length || 'None'}
                      </span>
                      <span>{t('Currently Due')}:</span>
                      <span className={`stripe-account__requirement stripe-account__requirement--${user.stripe_connect_account.requirements.currently_due?.length ? 'warning' : 'none'}`}>
                        {user.stripe_connect_account.requirements.currently_due?.length || 'None'}
                      </span>
                      <span>{t('Eventually Due')}:</span>
                      <span className={`stripe-account__requirement stripe-account__requirement--${user.stripe_connect_account.requirements.eventually_due?.length ? 'warning' : 'none'}`}>
                        {user.stripe_connect_account.requirements.eventually_due?.length || 'None'}
                      </span>
                    </div>
                    {user.stripe_connect_account.requirements.disabled_reason && (
                      <div className="stripe-account__error-box">
                        <strong>{t('Disabled Reason')}:</strong> {user.stripe_connect_account.requirements.disabled_reason}
                      </div>
                    )}
                  </div>
                )}

                {user.stripe_connect_account.settings && (
                  <div className="section">
                    <h4 className="section-header">{t('Settings')}</h4>
                    <div className="grid-layout">
                      <span>{t('Payout Schedule')}:</span>
                      <span className="stripe-account__value">{user.stripe_connect_account.settings.payouts?.schedule?.interval}</span>
                      <span>{t('Statement Descriptor')}:</span>
                      <span className="stripe-account__value">{user.stripe_connect_account.settings.payments?.statement_descriptor}</span>
                      <span>{t('Display Name')}:</span>
                      <span className="stripe-account__value">{user.stripe_connect_account.settings.dashboard?.display_name}</span>
                    </div>
                  </div>
                )}

                {user.stripe_connect_account.metadata && (
                  <div className="section">
                    <h4 className="section-header">{t('Account Info')}</h4>
                    <div className="grid-layout">
                      <span>{t('Business Type')}:</span>
                      <span className="stripe-account__value" style={{ textTransform: 'capitalize' }}>
                        {user.stripe_connect_account.metadata.business_type}
                      </span>
                      <span>{t('Verified At')}:</span>
                      <span className="stripe-account__value">
                        {user.stripe_connect_account.verified_at ? new Date(user.stripe_connect_account.verified_at).toLocaleString() : '-'}
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : null }

            {(user.stripe_connect_account_id) && (
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  refreshStripe(user.id);
                }}
              >
                {t('Refresh Stripe account status')}
              </a>
            )}
          </div>
        </Fieldset>
      </If>

      <Fieldset legend={t('Company information')} className="fieldset">
        <div className="card">
          <div className="form-group">
            <Input
              floatingLabel
              id='company_name'
              onChange={value => updateField('company_name', value)}
              label={t('Company name')}
              value={user.company_name}
              type='text'
            />
          </div>
          <div className="form-group">
            <Input
              floatingLabel
              id='company_registration_number'
              onChange={handleCompanyRegNumberChange}
              label={t('Company registration number')}
              value={user.company_registration_number}
              type='text'
            />
            {companyRegError && (
              <Notice type="error" className="notice">
                <p>{companyRegError}</p>
              </Notice>
            )}
          </div>
          <div className="form-group">
            <Input
              floatingLabel
              id='vat_identification_number'
              onChange={handleVatNumberChange}
              label={t('VAT identification number')}
              value={user.vat_identification_number || (user.country?.code && user.country.code.toLowerCase() !== 'global' ? user.country.code.toUpperCase() : '')}
              type='text'
            />
            {vatError && (
              <Notice type="error" className="notice">
                <p>{vatError}</p>
              </Notice>
            )}
          </div>
        </div>
      </Fieldset>

      <Fieldset legend={t('Invoice address')} className="fieldset">
        <div className="card">
          <div className="form-group">
            <Input
              floatingLabel
              id='invoice_address'
              onChange={value => updateField('invoice_address', value)}
              label={t('Address')}
              value={user.invoice_address}
              type='text'
            />
          </div>
          <div className="form-group">
            <Input
              floatingLabel
              id='invoice_postcode'
              onChange={value => updateField('invoice_postcode', value)}
              label={t('Postcode')}
              value={user.invoice_postcode}
              type='text'
            />
          </div>
          <div className="form-group">
            <Input
              floatingLabel
              id='invoice_city'
              onChange={handleCityChange}
              label={t('City')}
              value={user.invoice_city}
              type='text'
            />
          </div>
        </div>
      </Fieldset>

      <If condition={user.self_invoice && user.country.code !== 'global'}>
        <Fieldset legend={t('Payment settings')} className="fieldset">
          <div className="card">
            <div className="form-group">
              <div className='react-select__floating-label floating-label input has-content'>
                <Select
                  id='payment_method_id'
                  value={getSelectedPaymentMethod()}
                  isLoading={isLoading}
                  onChange={selected => updateField('payment_method_id', selected.value)}
                  placeholder={isLoading ? t('-- loading --') : t('Select payment method')}
                  options={paymentMethods ? paymentMethods.map(({ id, description }) => ({ value: id, label: description })) : []}
                  className='lavendla-select'
                  classNamePrefix='lavendla-select'
                  isMulti={false}
                  isClearable
                />
                <label htmlFor='payment_method_id'>{t('Payment method')}</label>
              </div>
            </div>
            <If condition={user.payment_method_id}>
              <div className="form-group">
                <Input
                  floatingLabel
                  id='payment_method_value'
                  onChange={handlePaymentMethodValueChange}
                  label={paymentMethodDescription}
                  value={user.payment_method_value}
                  type='text'
                  placeholder={
                    isBankAccount(paymentMethodDescription)
                      ? t('Only digits allowed')
                      : isGiroNumber(paymentMethodDescription)
                        ? t('Digits and hyphens allowed')
                        : ''
                  }
                />
                {bankAccountError && (
                  <Notice type="error" className="notice">
                    <p>{bankAccountError}</p>
                  </Notice>
                )}
              </div>
            </If>
          </div>
        </Fieldset>
      </If>
    </div>
  );
}

export default UserEconomyTab;
