import { useDispatch, useSelector } from 'react-redux';
import {
  addUserImage,
  createUser,
  deleteUser,
  fetchActiveOrders,
  deleteUserImage,
  getUser,
  persistUser,
  toggleInactive,
  refreshStripe,
  togglePause,
  updateUser,
  getPaymentMethods,
  getFeatures
} from '../actions/users';

function useUser () {
  const dispatch = useDispatch();
  const { user, paymentMethods, features } = useSelector(state => state.users);

  async function loadUser (id) {
    await dispatch(getUser(id));

    return user;
  }

  async function updateField (key, value) {
    dispatch(updateUser({ ...user, ...{ [key]: value } }));
  }

  return {
    user,
    paymentMethods,
    features,
    loadUser,
    addUserImage: (id, formData) => dispatch(addUserImage(id, formData)),
    deleteUserImage: (id) => dispatch(deleteUserImage(id)),
    destroy: (id) => dispatch(deleteUser(id)),
    fetchActiveOrders: (id) => dispatch(fetchActiveOrders(id)),
    toggleInactive: (id, deactivate = true) => dispatch(toggleInactive(id, deactivate)),
    refreshStripe: (id) => dispatch(refreshStripe(id)),
    togglePause: (id, paused = true) => dispatch(togglePause(id, paused)),
    create: () => dispatch(createUser()),
    persist: () => dispatch(persistUser()),
    getPaymentMethods: (countryId) => dispatch(getPaymentMethods(countryId)),
    getFeatures: () => dispatch(getFeatures()),
    updateField,
    dispatchUser: (data) => dispatch(updateUser(data))
  };
}

export default useUser;
