import API from '../../stores/API';
import { getID, getToken, removeID, removeToken, saveID, saveToken } from 'Components/localstorage';
import cloneDeep from 'lodash/cloneDeep';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { setCountry } from 'ReduxActions/countries';
import i18next from 'i18next';

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_FEATURES = 'SET_FEATURES';

export const ROLES = {
  ADMIN: 'admin',
  FUNERAL: 'funeral_advisor',
  LEGAL: 'legal_advisor',
  TOMBSTONE: 'tombstone_advisor',
  SUPPLIER: 'supplier',
  THERAPIST: 'therapy_user',
  OFFICIANT: 'officiant_user'
};

function getDefaultIncludes () {
  return [
    'competence_areas',
    'suppliers',
    'schedule',
    'phone_hours',
    'user_profiles',
    'locations',
    'reviews',
    'user_notifications',
    'address',
    'countries',
    'country',
    'users',
    'user_features',
    'site',
    'stripe_connect_account'
  ];
}

function recieveUser (payload) {
  return {
    type: SET_USER,
    payload
  };
}

function recieveUsers (payload) {
  return {
    type: SET_USERS,
    payload
  };
}

function recieveUsersPagnination (payload) {
  return {
    type: SET_PAGINATION,
    payload
  };
}

function recieveLoggedInUser (payload) {
  return {
    type: SET_LOGGED_IN_USER,
    payload
  };
}

function receiveSetIsAuthenticated (payload) {
  return {
    type: SET_IS_AUTHENTICATED,
    payload
  };
}

function receivePaymentMethods (payload) {
  return {
    type: SET_PAYMENT_METHODS,
    payload
  };
}

function receiveFeatures (payload) {
  return {
    type: SET_FEATURES,
    payload
  };
}

function transformSchedule ({ schedule = [] }) {
  const weeklySchedule = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  };

  if (Array.isArray(schedule)) {
    for (const s of schedule) {
      if (Array.isArray(weeklySchedule[s.weekday])) {
        weeklySchedule[s.weekday].push(s);
      }
    }
  }

  return weeklySchedule;
}

function transformPhoneHours ({ phone_hours: phoneHours = [] }) {
  const weeklyPhoneHours = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  };

  if (Array.isArray(phoneHours)) {
    for (const s of phoneHours) {
      if (Array.isArray(weeklyPhoneHours[s.weekday])) {
        weeklyPhoneHours[s.weekday].push(s);
      }
    }
  }

  return weeklyPhoneHours;
}

function transformUser (user) {
  if (!user) {
    return user;
  }

  if (user.professionally_active_since === '0000-00-00') {
    user.professionally_active_since = '';
  }

  user.schedule = transformSchedule(user);
  user.phone_hours = transformPhoneHours(user);

  return user;
}

export function updateUser (data) {
  return async (dispatch) => {
    dispatch(recieveUser(data));
  };
}

export function updateLoggedInUser (data) {
  return async (dispatch) => {
    const user = transformUser(data);
    dispatch(recieveLoggedInUser(user));
  };
}

export function login (email, password) {
  return async (dispatch) => {
    const response = await API.POST('/auth/login', { email, password });
    if (response.status === 'error') {
      removeToken();
      removeID();

      return false;
    }

    const { token, user } = response;

    saveID(user.id);
    saveToken(token);

    await dispatch(getLoggedInUser());

    return true;
  };
}

export function getPaymentMethods (countryId) {
  return async (dispatch) => {
    const { data, status } = await API.GET(`/payment-methods?country_id=${countryId}&limit=0`);

    if (status === 'error') {
      return false;
    }

    dispatch(receivePaymentMethods(data));

    return true;
  };
}

export function getFeatures () {
  return async (dispatch) => {
    const { data, status } = await API.GET('/features');

    if (status === 'error') {
      return {};
    }

    dispatch(receiveFeatures(data));

    return data;
  };
}

export function sendResetEmail (email) {
  return async () => {
    return API.POST('/auth/send-reset-link-email', { email });
  };
}

export function loginWithGoogle (data) {
  return async (dispatch) => {
    const response = await API.POST('/auth/google/login', data);
    if (response.status === 'error') {
      removeToken();
      removeID();

      return response;
    }

    const { token, user } = response;

    saveID(user.id);
    saveToken(token);

    await dispatch(getLoggedInUser());

    return response;
  };
}

export function logout () {
  return (dispatch) => {
    // Logout the user from the api
    API.POST('/auth/logout');

    removeToken();
    removeID();

    dispatch(recieveLoggedInUser(null));
    dispatch(receiveSetIsAuthenticated(false));
  };
}

export function getLoggedInUser () {
  return async (dispatch, getState) => {
    const { users } = getState();
    const id = getID();
    const token = getToken();

    if (users.loggedInUser) {
      return users.loggedInUser;
    }

    if (!id || !token) {
      dispatch(receiveSetIsAuthenticated(false));
      return null;
    }

    const includes = getDefaultIncludes();
    const { data, status, errors } = await API.GET(`/users/${id}?include=${includes.join(',')}`);

    if (status === 'error') {
      dispatch(receiveSetIsAuthenticated(false));
      showWarningNotice(errors.error_message);
      return null;
    }

    await dispatch(recieveLoggedInUser(transformUser(data)));
    await dispatch(receiveSetIsAuthenticated(true));

    const dict = {
      se: 'sv_se',
      dk: 'da_dk',
      no: 'no_nb',
      fi: 'fi_fi',
      gb: 'en_gb'
    };

    // Fallback to en. This will need to be modified to support sv_FI and fi_FI later
    const lang = (typeof dict[data.country.code] === 'undefined') ? 'en_gb' : dict[data.country.code];

    await i18next.changeLanguage(lang);

    return data;
  };
}

export function createUser () {
  return async (dispatch, getState) => {
    const { users: { user } } = getState();
    const { status, errors, data } = await API.POST('/users', user);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return false;
    }

    showNotice(i18next.t('The user has been created successfully'));

    return data;
  };
}

export function getUser (id, includes = getDefaultIncludes()) {
  return async (dispatch) => {
    dispatch(recieveUser(null));

    const { status, errors, data } = await API.GET(`/users/${id}?include=${includes.join(',')}`);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return;
    }

    if (data.country) {
      dispatch(setCountry(data.country));
    }

    dispatch(recieveUser(transformUser(data)));
  };
}

export function getUsers ({
  page = 1,
  country = null,
  location = null,
  role = null,
  withActivity = true,
  withPhoneQueueInfo = true,
  search = null,
  competenceAreas = [],
  limit = 30
}) {
  return async (dispatch, getState) => {
    let uri = `/users?limit=${limit}&include=locations,competence_areas&page=${page}`;

    if (country && location) {
      uri += `&location_id=${location}`;
    }

    if (role) {
      uri += `&role=${role}`;
    }

    if (search) {
      uri += `&search=${search}`;
    }

    if (competenceAreas.length) {
      uri += `&competence_areas=${competenceAreas.map(({ id }) => id).join(',')}`;
    }

    if (withActivity) {
      uri += '&with_latest_activity=1';
    }

    if (withPhoneQueueInfo) {
      uri += '&with_is_active_in_phone_queue=1';
    }

    if (country) {
      uri += `&country_id=${country.id}`;
    }

    dispatch(recieveUsersPagnination(null));
    const { status, errors, data, meta } = await API.GET(uri);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return;
    }

    dispatch(recieveUsers(data));
    dispatch(recieveUsersPagnination(meta.pagination));
  };
}

export function toggleFavoritesAtTop () {
  return async (dispatch, getState) => {
    const { users } = getState();
    const id = users.loggedInUser?.id;

    if (!id) {
      return;
    }

    const { status, errors, data } = await API.POST(`/users/${id}/favorites-at-top`);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return;
    }

    dispatch(updateLoggedInUser(data));
  };
}

export function persistUser () {
  return async (dispatch, getState) => {
    const { users } = getState();
    const clonedUser = cloneDeep(users.user);

    delete clonedUser.api_token;
    clonedUser.suppliers = clonedUser.suppliers.map(({ id }) => id);

    const { status, errors, data } = await API.PUT(`/users/${clonedUser.id}?include=locations`, clonedUser);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return;
    }

    dispatch(recieveUser(transformUser(data)));
    showNotice(i18next.t('The user has been updated successfully'));
  };
}

export function deleteUser (id) {
  return async () => {
    const response = await API.DELETE(`/users/${id}`);

    if (response.status === 'error') {
      showWarningNotice(response.errors.error_message);
      return;
    }

    showNotice(i18next.t('The user has been deleted successfully'));

    return true;
  };
}

export function fetchActiveOrders (id) {
  return async () => {
    const { data } = await API.GET(`/users/${id}/get-active-orders`);

    return data;
  };
}

export function toggleInactive (id, deactivate = true) {
  return async () => {
    let uri = `/users/${id}/toggle-inactive`;
    if (deactivate) {
      uri += '?deactivate=1';
    }

    const { data, status, errors } = await API.POST(uri);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return false;
    }

    return data;
  };
}

export function refreshStripe (id) {
  return async (dispatch, getState) => {
    const uri = `/users/${id}/refresh-stripe`;
    const { data, status, errors } = await API.POST(uri);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return false;
    }

    dispatch(recieveUser(transformUser(data)));
    showNotice(i18next.t('Stripe account status has been refreshed'));
  };
}

export function togglePause (id, paused = true) {
  return async () => {
    let uri = `/users/${id}/toggle-pause`;
    if (paused) {
      uri += '?pause=1';
    }

    const { data, status, errors } = await API.POST(uri);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return false;
    }

    return data;
  };
}

export function addUserImage (id, formData) {
  return async (dispatch) => {
    const { data, status, errors } = await API.POST(`/users/${id}/attachment`, formData, false);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return false;
    }

    await dispatch(recieveUser(transformUser(data)));
    return data;
  };
}

export function deleteUserImage (id) {
  return async (dispatch) => {
    const { data, status, errors } = await API.DELETE(`/users/${id}/attachment`);

    if (status !== 'ok') {
      showWarningNotice(errors.error_message);
      return false;
    }

    await dispatch(recieveUser(transformUser(data)));
    return data;
  };
}
